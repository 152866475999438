import axiso from 'axios'
import {Message} from "element-ui";

const axios = axiso.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

axios.interceptors.response.use(res => {
  return Promise.resolve(res);
}, err => {
  
  Message({
    type: 'error',
    message: err.response.data.message,
    duration: 2000
  })

  return Promise.reject(err);
})

export default axios
