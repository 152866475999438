// login页面api

import axios from "@/utils/request";

// 发送短息验证码
export function sendMessage(appId,mobile) {
  return axios({
    url: `/application/${appId}/authorization/phone-verification-code`,
    method: 'post',
    data: { number: mobile }
  })
}

// 登录
export function login(appId, mobile, verificationCode) {
  return axios({
    url: `/application/${appId}/authorization`,
    method: 'post',
    data: {type: 'PHONE_VERIFICATION_CODE', params: {phone: {number: mobile}, verificationCode: verificationCode}}
  })
}

// 根据userId获取用户详细信息
export function getUserInfo(exhibitionId, userId) {
  return axios({
    url: `/user/${userId}/visit-record`,
    method: 'get',
    params: { 'exhibition.id': exhibitionId}
  })
}
