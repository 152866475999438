<template>
  <div
    id="login"
    :style="{
      backgroundImage: `url('${background}')`,
      backgroundColor: backgroundColor,
    }"
  >
    <div class="login-box">
      <div class="title">注册登录</div>
      <div class="form">
        <el-form
          :model="dataForm"
          :rules="rules"
          ref="dataForm"
          label-width="300"
          label-position="left"
        >
          <!-- <el-form-item label="" prop="username">
            <el-input
              v-model="dataForm.username"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="" prop="mobile">
            <el-input
              v-model="dataForm.mobile"
              placeholder="请输入电话"
            ></el-input>
          </el-form-item>
          <slider-verify
            v-if="imgList.length > 0"
            ref="sliderVerify"
            :w="300"
            :h="150"
            :imgs="imgList"
            sliderText="向右滑动完成验证"
            @success="handlerSuccess"
            @fail="handlerFail"
            @refresh="handlerRefresh"
          ></slider-verify>
          <el-form-item label="" prop="verificationCode">
            <el-input
              v-model="dataForm.verificationCode"
              placeholder="请输入验证码"
            >
              <template slot="append">
                <el-button
                  type="text"
                  :style="{ color: application.properties.primaryColor }"
                  :disabled="timeCount > 0 ? true : false"
                  @click="sendverificationCode"
                  >{{
                    timeCount > 0
                      ? "重新获取(" + timeCount + ")s"
                      : "获取验证码"
                  }}
                </el-button>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <button
          class="btn"
          :style="{ backgroundColor: primaryColor }"
          @click="login"
        >
          登录
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import { sendMessage } from "@/api/auth";
import "@/assets/css/myCSS.css";
import SliderVerify from "@/views/login/SliderVerify";
// import img0 from "@/assets/images/1.jpg";
// import img1 from "@/assets/images/2.jpg";
// import img2 from "@/assets/images/3.jpg";
// import img3 from "@/assets/images/4.jpg";
// import img4 from "@/assets/images/5.jpg";
// import img5 from "@/assets/images/6.jpg";
// import back from "@/assets/images/back.jpg";

import permission from "../../router/permission";

import UserVisitRecordService from "../../service/user-visit-record";

export default {
  name: "Login",
  components: { SliderVerify },
  computed: {
    ...mapState({
      //全局变量
      environment: (state) => state.environment,
      source: (state) => state.source,
      inviter: (state) => state.inviter,
      inviteSource: (state) => state.inviteSource,
      //样式参数
      primaryColor: (state) => state.application.properties.primaryColor,
      background: (state) => state.application.properties.background,
      backgroundColor: (state) => state.application.properties.backgroundColor,
      //业务参数
      exhibition: (state) => state.exhibition,
      application: (state) => state.application,
      account: (state) => state.account,
      user: (state) => state.user,
      phone: (state) => state.phone,
      visitRecord: (state) => state.visitRecord,
      businessCard: (state) => state.businessCard,
    }),
  },
  data() {
    // 手机号表单校验规则
    let mobileValidator = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!this.isMobile(value)) {
        callback(new Error("手机号格式有误"));
      } else {
        callback();
      }
    };
    return {
      dataForm: {
        username: "",
        mobile: "",
        verificationCode: "",
      },
      rules: {
        username: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, validator: mobileValidator, trigger: "blur" },
        ],
        verificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      timeCount: 0,
      // imgList: [img0, img1, img2, img3, img4, img5],
      // imgList: [back],
      imgList: [],
      botVerifyPass: false,
      timer: null,
    };
  },
  methods: {
    ...mapMutations({
      setPhone: "setPhone",
    }),
    ...mapActions({
      addAuthorization: "addAuthorization",
      getVisitRecord: "getVisitRecord",
    }),
    // 登录
    async login() {
      try {
        const valid = await this.$refs["dataForm"].validate();

        if (valid) {
          const phoneParam = { number: this.dataForm.mobile };

          const params = {
            data: {
              type: "PHONE_VERIFICATION_CODE",
              params: {
                phone: phoneParam,
                verificationCode: this.dataForm.verificationCode,
              },
            },
          };

          await this.addAuthorization(params);

          await this.getVisitRecord();

          permission.checkVisitRecord(
            this.visitRecord,
            async () => {
              //未验证手机号
              await UserVisitRecordService.addUserVisitRecord(this.user, {
                properties: {
                  applicationId: this.application.id,
                  environment: this.environment,
                  source: this.source,
                  inviter: this.inviter,
                  inviteSource: this.inviteSource,
                },
                progress: "VERIFIED_PHONE",
                phone: {
                  id: this.phone.id,
                },
                exhibition: {
                  id: this.exhibition.id,
                },
              });

              await this.getVisitRecord();

              this.$router.push({
                path: "/index/information",
              });
            },
            () => {
              // 未完善信息
              this.$router.push({
                path: "/index/information",
              });
            },
            () => {
              //未填写问卷
              this.$router.push({ path: "/index/questionnaire" });
            },
            () => {
              //预登记完成
              this.$router.push({ path: "/index/success" });
            }
          );
        }
      } catch (error) {
        console.log(error);
        if (error.code) {
          switch (error.code) {
            case "404-visitRecord":
              //兼容之前的逻辑
              this.$router.push({ path: "/login" });
              break;
            default:
              break;
          }

          return;
        }
      }
    },
    // 发送手机验证码
    sendverificationCode() {
      this.$refs["dataForm"].validateField("mobile", (errMsg) => {
        if (!errMsg && this.botVerifyPass) {
          this.conutdown();
          sendMessage(this.application.id, this.dataForm.mobile).then((res) => {
            if (res.status === 201) {
              this.$message({
                type: "success",
                message: "验证码发送成功",
                duration: 2000,
              });
            } else {
              this.$message({
                type: "success",
                message: "验证码发送失败",
                duration: 2000,
              });
            }
          });
        } else {
          this.$refs.sliderVerify.reset();
          this.$message({
            type: "error",
            message: "短信发送失败，请重试！",
            duration: 2000,
          });
        }
      });
    },
    // 正则校验手机号
    isMobile(mobile) {
      const MOBILE_NUMBER_REG = /^1[3456789]\d{9}$/;
      return MOBILE_NUMBER_REG.test(mobile);
    },
    // 人机校验通过回调
    handlerSuccess() {
      this.botVerifyPass = true;
      this.sendverificationCode();
    },
    handlerFail() {
      this.$message({
        type: "error",
        message: "人机验证失败,请重试！",
        duration: 2000,
      });
      this.botVerifyPass = false;
    },
    handlerRefresh() {
      this.botVerifyPass = false;
    },
    // 倒计时
    conutdown() {
      const TIME_COUNT = 30;
      if (!this.timer) {
        this.timeCount = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.timeCount > 0 && this.timeCount <= TIME_COUNT) {
            this.timeCount--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
  mounted() {
    this.imgList = [this.background];
  },
};
</script>

<style scoped lang="less">
#login {
  height: 100%;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  // background-size: 100%;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;

  // background-size: 66%;

  .login-box {
    width: 360px;
    height: 483px;
    position: absolute;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    top: 88px;
    right: 266px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    .title {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 28px;
      margin: 30px 0;
    }

    .form {
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;
    }

    .btn {
      width: 310px;
      height: 40px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fff;
      line-height: 28px;
      letter-spacing: 10px;
      border: none;
      outline: none;
      margin-bottom: 20px;
    }
  }
}
</style>
